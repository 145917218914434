/* Style for hovering over header */
th:hover span.arrow {
    color: #6c757d !important;
}

th.table-active span.arrow {
    color: #000 !important;
}

/* Style for hovering row */
tbody.highlight-row-hover tr:hover td {
    background-color: var(--cui-gray-100);
}

/* Hide overflow */
table {
    overflow: hidden;
    border-radius: 0.375rem;
}
