a {
    color: #0366d6;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.bottom-button-group a,
.bottom-button-group button {
    width: 100px;
    margin: 12px 6px;
}

.button-center {
    text-align: center;
}

.button-col-format {
    margin: 5px 4px;
    padding: 0;
}

.center-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    text-align: center;
}

code {
    color: #E01A76;
}

.list-group-scroll-400 {
    max-height: 400px;
    overflow-y: auto;
}

.relative-position {
    position: relative;
}

/* Adds * after input label */
.required-label:after {
    content: " *";
    color: red;
}

.small-text {
    font-size: 0.8rem;
}

/* Header menu search dropdown */
.search-menu {
    position: fixed !important;
    left: 50% !important;
    transform: translate(-50%, 50px) !important;
    width: 1100px !important;
}

/* Size less than xl */
@media all and (max-width: 1199px) {
    .search-menu {
        width: 900px !important;
    }
}

/* Size less than lg */
@media all and (max-width: 991px) {
    .search-menu {
        width: 500px !important;
    }
}

/* Size xs */
@media all and (max-width: 575px) {
    .search-menu {
        width: 300px !important;
    }
}
