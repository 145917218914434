#login-alternate-btn {
    color: darkgrey;
}

    #login-alternate-btn:hover {
        color: #000;
        text-decoration: underline;
    }

#login-btn {
    margin: auto;
    min-width: 200px;
}

#login-container {
    background: url('../assets/TitaniumTruck.jpg');
    background-size: cover;
    min-height: 100vh;
    min-width: 100vw;
}

#login-card {
    border-radius: 16px;
    display: flex;
    min-width: 400px;
    min-height: 250px;
    position: absolute;
}

#titanium-logo {
    max-width: 300px;
}

@media (min-width: 992px) {
    #login-card {
        left: 75%;
    }
}