/* Style for scoreboard table */

/* Color markings for specific table cells using the associated tag */
mark-lime {
    background-color: lime !important;
}

mark-yellow {
    background-color: yellow !important;
}

mark-orange {
    background-color: orange !important;
}

mark-red {
    background-color: #f95050 !important;
}

td.share {
    vertical-align: middle;
}

.scoreboard-table {
    white-space: nowrap;
}

    .scoreboard-table .commission-deficit td {
        color: #f9690e;
    }

    .scoreboard-table .margin-deficit td {
        color: #f00;
    }

/* Info */
.text-blue {
    color: #31708f !important;
}

/* Warning */
.text-brown {
    color: #68470e !important;
}

/* Success */
.text-green {
    color: #3c763d !important;
}

/* Danger */
.text-red {
    color: #e10400 !important;
}

/* Primary */
.text-blue-primary {
    color: #337ab7 !important;
}

/* Muted */
.text-grey {
    color: #777777 !important;
}

/* Top Button row */
#top-button-row {
    flex-grow: 0;
    white-space: nowrap;
}
