/* Div Components */
status-label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}

payment-quality-bar {
    display: block;
    height: 25px;
    text-align: center;
    color: #fff;
    border-radius: 6px;
}

/* Labels */
label {
    width: 100%;
}

/* Header section title with buttons style */
.title-display-content {
    display: inline-flex;
    width: 100%;
}

/* Makes the list scroll */
ul.scroll-list {
    overflow-y: auto;
    max-height: 350px;
}

/* Other items*/
.full-width {
    width: 100%;
    display: block;
}

a.no-link-decor {
    color: inherit;
}

/* Placeholder */
.placeholder-row .list-group-item {
    height: 55px;
}
