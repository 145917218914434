/* Remove padding on tab link sides */
.account-type-navigation.nav > .nav-item > .nav-link {
    padding-right: 8px;
    padding-left: 8px;
}

/* Background colours for tabs */
#prospects.active,
#research.active,
.prospect-active #research {
    background-color: #FFDDAE;
    color: #000;
}

#outreach.active,
.prospect-active #outreach {
    background-color: #F0D7B8;
    color: #000;
}

#connect.active,
.prospect-active #connect {
    background-color: #E0D2C2;
    color: #000;
}

#building-interest.active,
.active-pipeline-active #building-interest,
.prospect-active #building-interest {
    background-color: #D1CCCC;
    color: #000;
}

#discovery-meeting.active,
.active-pipeline-active #discovery-meeting,
.prospect-active #discovery-meeting {
    background-color: #C2C6D7;
    color: #000;
}

#rfp-quote.active,
.active-pipeline-active #rfp-quote,
.prospect-active #rfp-quote {
    background-color: #B2C0E1;
    color: #000;
}

#presentation-negotiate.active,
.active-pipeline-active #presentation-negotiate,
.prospect-active #presentation-negotiate {
    background-color: #A3BBEB;
    color: #000;
}

#first-load-implementation.active,
.active-pipeline-active #first-load-implementation,
.prospect-active #first-load-implementation {
    background-color: #93B5F5;
    color: #000;
}

#customers.active {
    background-color: #84AFFF;
    color: #000;
}
