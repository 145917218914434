
/* Overriding css in Typeahead component */

/* Prevent double clear buttons showing */
.rbt-close .rbt-close-content {
    display: none;
}

/* Change arrow key menu item selection colour */
.rbt-menu a.dropdown-item.active {
    color: #fff !important;
    background-color: #2791FB !important;
}

/* Change mouseover colour */
/*.dropdown-item:focus, .dropdown-item:hover {
    color: #fff !important;
    background-color: #2791FB !important;
}*/