.react-calendar {
    border-radius: 0.375rem;
}

/* Sets the css for the react date to not use their styling for border */
.react-date-picker__wrapper {
    border: none;
}

/* Length for edit todo date picker */
#todo-edit-date-picker.react-date-picker {
    max-width: 300px;
    min-width: 185px;
}

#todo-sort-nav span.arrow {
    color: #FFF;
}

#todo-sort-nav .nav-link {
    color: #6c757d !important;
}

    #todo-sort-nav .nav-link.active,
    #todo-sort-nav .nav-link.active span.arrow {
        color: #000 !important;
    }
