/* Body should fill the height of screen*/
#body-component {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 100vh;
}

    /* Specifies the body (card) with content should fill vertical space */
    #body-component > div.card {
        flex-grow: 1;
    }